import {UPDATE_INSTALL_MESSAGE_STATE, UPDATE_SIDE_NAV_STATE, UPDATE_SITE_TITLE} from './globalTypes'

export const updateInstallMessageState = (value) => ({
    type: UPDATE_INSTALL_MESSAGE_STATE,
    value: value
});

export const updateSideNavState = (value) => ({
    type: UPDATE_SIDE_NAV_STATE,
    value: value
});

export const updateSiteTitle = (value) => ({
    type: UPDATE_SITE_TITLE,
    value: value
});
