import {UPDATE_INSTALL_MESSAGE_STATE, UPDATE_SIDE_NAV_STATE, UPDATE_SITE_TITLE} from './globalTypes'

const initialState = {
    installMessageShow: false,
    devEnv: process.env.NODE_ENV !== 'production',
    sideNavOpen: false,
    siteTitle: 'PWA - Cocktail'
};

export default function globalReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_INSTALL_MESSAGE_STATE:
            return Object.assign({}, state, {
                installMessageShow: action.value
            });
        case UPDATE_SIDE_NAV_STATE:
            return Object.assign({}, state, {
                sideNavOpen: action.value
            });
        case UPDATE_SITE_TITLE:
            return Object.assign({}, state, {
                siteTitle: action.value
            });
        default:
            return state;
    }
}
