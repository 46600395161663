/**
 * Material-UI Bug: finddomnode is deprecated in strictmode material-ui *
 * Solution:https://github.com/mui-org/material-ui/issues/13394
 */
import {unstable_createMuiStrictModeTheme} from '@material-ui/core/styles';
import {themeName, palette, typography, shape, props} from './baseTheme'
import overrideTheme from './overrideTheme';

let theme = unstable_createMuiStrictModeTheme({themeName, palette, typography, shape, props});
theme = overrideTheme(theme);

export default theme;
