import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {mockDataHostingList} from '../../api/mockData';
import {updateSiteTitle} from "../../redux/global/globalAction";
import HostinglistHeaderData from '../utils/hostinglist/hostinglistHeaderData';
import HostinglistTable from '../utils/hostinglist/hostinglistTable';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {withTranslation} from "react-i18next";
import {withStyles} from '@material-ui/styles';

const useStyles = (theme) => ({
    hostinglist: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    hostinglistInformationAcc: {
        boxShadow: 'none',

        '&:before': {
            height: 0
        }
    },
    hostinglistInformationAccHeader: {
        display: 'none'
    },
    hostinglistInformationAccDetail: {
        padding: 0
    },
    informationButtonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    informationButton: {
        textTransform: 'initial',
        backgroundColor: 'transparent',

        '&:hover, &:active, &:focus': {
            backgroundColor: 'transparent'
        }
    },
    divider: {
        marginBottom: theme.spacing(4)
    }
});

class HostingList extends React.Component {
    constructor() {
        super();
        this.state = {
            informationTabs: true
        };
    }

    componentDidMount() {
        this.props.updateSiteTitle(this.props.t('route_hostinglist'));
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;

        return (
            <div className={classes.hostinglist}>
                <Accordion expanded={this.state.informationTabs} className={classes.hostinglistInformationAcc}
                           component={'div'} onChange={() => {}}>
                    <AccordionSummary className={classes.hostinglistInformationAccHeader}></AccordionSummary>
                    <AccordionDetails className={classes.hostinglistInformationAccDetail}>
                        <HostinglistHeaderData totalSales={mockDataHostingList.totalSales}
                                               netSales={mockDataHostingList.netSales}
                                               pieData={mockDataHostingList.pieData}/>
                    </AccordionDetails>
                </Accordion>
                <div className={classes.informationButtonWrapper}>
                    {this.state.informationTabs ?
                        <Button onClick={() => this.setState({informationTabs: !this.state.informationTabs})}
                                className={classes.informationButton}
                                startIcon={<ExpandLessIcon/>}
                        >{t('expand_less_informations')}</Button>
                        : <Button onClick={() => this.setState({informationTabs: !this.state.informationTabs})}
                                  className={classes.informationButton}
                                  startIcon={<ExpandMoreIcon/>}
                        >{t('expand_more_informations')}</Button>
                    }
                </div>
                <Divider className={classes.divider}/>
                <HostinglistTable data={mockDataHostingList.tableContent}/>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle,
});

const mapDispatchToProps = {
    updateSiteTitle,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
    withTranslation(),
)(HostingList);
