import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/styles';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';


const useStyles = (theme) => ({
    table: {
        width: '100%',
    }
});

class DashboardHighStorageUsageTable extends React.Component {
    render() {
        const {classes} = this.props;
        const {rows} = this.props;
        const {t} = this.props;
        const {rowHeaders} = this.props;

        return (
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {rowHeaders.map((rowHeader, index) => (
                                <TableCell align={rowHeader.alignment} key={index}>
                                    <strong>{t(rowHeader.name)}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.appName}
                                </TableCell>
                                <TableCell>{row.client}</TableCell>
                                <TableCell>{row.url}</TableCell>
                                <TableCell>{row.storageDB}</TableCell>
                                <TableCell>{row.storageProject}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default compose(
    withStyles(useStyles),
    withTranslation()
)(DashboardHighStorageUsageTable);
