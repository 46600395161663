import React from 'react';
import {connect} from 'react-redux';
import {updateInstallMessageState} from '../../redux/global/globalAction';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {IosShareIcon} from './additionalIcons';
import UserAgent from '../../service/userAgent';
import {Trans, withTranslation} from 'react-i18next';
import {compose} from 'redux';

class PwaInstallMessage extends React.Component {

    componentDidMount() {
        if (UserAgent.isIos() && !UserAgent.isInStandaloneMode() && !localStorage.getItem('installMessageShown')) {
            this.props.updateInstallMessageState(true);
        }
    }

    handleInstallMessageClose = (doNotShowAgain) => () => {
        if (doNotShowAgain) {
            localStorage.setItem('installMessageShown', 'true');
        }

        this.props.updateInstallMessageState(false);
    };

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={this.props.installMessageShow}
                onClose={this.handleInstallMessageClose()}
                aria-labelledby="install-dialog-title"
                aria-describedby="install-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey="pwa_install_message" components={{shareIcon: <IosShareIcon/>}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button fullWidth={true} onClick={this.handleInstallMessageClose(true)} variant="outlined">
                        {t('do_not_show_again')}
                    </Button>
                    <Button fullWidth={true} onClick={this.handleInstallMessageClose()} autoFocus>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    installMessageShow: state.global.installMessageShow,
});

const mapDispatchToProps = {
    updateInstallMessageState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
)(PwaInstallMessage);
