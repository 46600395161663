import React from 'react';
import {connect} from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import {updateSideNavState} from '../../../redux/global/globalAction';
import {compose} from 'redux';

class SideNavigationClose extends React.Component {

    handleSideNavToggleClick() {
        this.props.updateSideNavState(false);
    }

    render() {
        return (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
                this.handleSideNavToggleClick();
            }}>
                <ChevronLeftIcon/>
            </IconButton>
        );
    }
}

const mapStateToProps = state => ({
    sideNavOpen: state.global.sideNavOpen,
});

const mapDispatchToProps = {
    updateSideNavState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )
)(SideNavigationClose);
