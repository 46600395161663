import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {ThemeProvider, responsiveFontSizes} from '@material-ui/core/styles';
import App from './components/app';
import theme from './theme/theme';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import './i18n';

if (process.env.NODE_ENV === 'development') {
    require('./debug.css');
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={responsiveFontSizes(theme)}>
                    <App/>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

serviceWorker.register();
