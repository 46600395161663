import React from 'react';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {withStyles} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import MaterialUIGrid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveIcon from '@material-ui/icons/Save';
import {
    SelectionState,
    RowDetailState,
    SortingState,
    IntegratedSelection,
    IntegratedSorting
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    TableSelection,
    TableRowDetail
} from '@devexpress/dx-react-grid-material-ui';
import {GridExporter} from '@devexpress/dx-react-grid-export';
import saveAs from 'file-saver';

const useStyles = (theme) => ({
    hostingTable: {
        width: '100%'
    },
    detailRow: {
        padding: theme.spacing(3)
    },
    filterSearchGroup: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    searchField: {
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            width: 350,
            marginBottom: theme.spacing(0)
        },

        '&:hover:not(.Mui-disabled)::before, &:before': {
            borderColor: theme.palette.common.black
        },

        '&:after': {
            borderBottom: '0'
        }
    },
    filterAcc: {
        marginBottom: theme.spacing(2)
    },
    filterSelect: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterSelectInput: {
        width: '100%'
    },
    filterSelectResetButton: {
        marginBottom: theme.spacing(2) * -1
    },
    filterCheckboxInput: {
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(2) * -1
        }
    },
    exportButton: {
        marginRight: theme.spacing(2)
    }
});

class HostingListTable extends React.Component {
    constructor(props) {
        super();
        let rows = props.data;
        let allClients = [];
        let allDomains = [];
        let allSubscriptions = [];

        rows.forEach(row => {
            if (!(allClients.includes(row.client))) {
                allClients.push(row.client);
            }
            if (!(allDomains.includes(row.domain))) {
                allDomains.push(row.domain);
            }
            if (!(allSubscriptions.includes(row.subscription))) {
                allSubscriptions.push(row.subscription);
            }
        });

        allClients.sort();
        allDomains.sort();
        allSubscriptions.sort();

        this.exportRef = React.createRef();
        this.state = {
            initialData: props.data,
            rows: rows,
            exportRows: rows,
            selection: [],
            windowHeight: 0,
            searchValue: '',
            searchState: '',
            filterAccExpanded: false,
            allClients: allClients,
            allDomains: allDomains,
            allSubscriptions: allSubscriptions,
            activeClientFilter: '',
            activeDomainsFilter: '',
            activeSubscriptionsFilter: '',
            activeInitalCosts: false,
            querySet: false
        };
    }

    handleSelection(selection) {
        let selectionArray = [];
        selection.forEach(row => {
            selectionArray.push(this.state.rows[row]);
        });

        if (selectionArray.length > 0) {
            this.setState({selection: selection, exportRows: selectionArray});
        } else {
            this.setState({selection: selection});
        }
    }

    handleSearch(value) {
        let tempState = this.state;
        tempState.searchValue = value;
        if (value.length >= 3) {
            tempState = this.filterFunction(tempState, tempState.rows);
            tempState.rows = this.searchFunction(value, tempState.rows);
            tempState.selection = [];
        } else {
            tempState = this.filterFunction(tempState, tempState.rows);
        }
        tempState.exportRows = tempState.rows;
        this.setState(tempState);
    }

    handleFilterSelection(value, filter) {
        let tempState = this.state;

        // eslint-disable-next-line
        switch (filter) {
            case 'client':
                tempState.activeClientFilter = value;
                break
            case 'subscription':
                tempState.activeSubscriptionsFilter = value;
                break
            case 'domain':
                tempState.activeDomainsFilter = value;
                break;
            case 'initialcosts':
                tempState.activeInitalCosts = value;
                break;
        }

        tempState = this.filterFunction(tempState, tempState.initialData);
        tempState.rows = this.searchFunction(tempState.searchValue, tempState.rows);
        tempState.exportRows = tempState.rows;

        this.setState(tempState);
    }

    searchFunction(value, initalRows) {
        const rows = initalRows;
        const searchValue = value.toLowerCase();
        let foundRows = [];

        rows.forEach(row => {
            for (let key in row) {
                let obj = row[key];

                if (typeof obj === 'string') {
                    if (obj.toLowerCase().includes(searchValue)) {
                        if (!foundRows.includes(row)) {
                            foundRows.push(row);
                        }
                        break;
                    }
                }
            }
        });

        return foundRows;
    }

    filterFunction(state) {
        let initialData = state.initialData;
        let alreadyFiltered = false;
        let filtered = false;
        let rows = [];

        function filterAlg(filterValue, findKey) {
            if (filterValue || filterValue.length > 0) {
                if (alreadyFiltered) {
                    let tempRows = [];

                    rows.forEach(row => {
                        if (row[findKey.valueOf()] === filterValue) {
                            tempRows.push(row);
                        }
                    });

                    rows = tempRows;
                } else {
                    initialData.forEach(row => {
                        if (row[findKey.valueOf()] === filterValue) {
                            rows.push(row);
                        }
                    });
                    alreadyFiltered = true;
                }

                filtered = true;
            }
        }

        filterAlg(state.activeClientFilter, 'client');
        filterAlg(state.activeDomainsFilter, 'domain');
        filterAlg(state.activeSubscriptionsFilter, 'subscription');
        filterAlg(state.activeInitalCosts, 'initialcosts');

        if (filtered) {
            state.rows = rows;
            state.exportRows = rows;
            state.selection = [];
        } else {
            state.rows = initialData;
        }

        return(state);
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;
        const {location} = this.props;
        let rows = this.state.rows;

        const query = new URLSearchParams(location.search);

        if (query.get('searchvalue') && !this.state.querySet) {
            if (this.state.searchValue === '') {
                this.setState({querySet: true});
                this.handleSearch(query.get('searchvalue'));
            }
        }

        rows.forEach((row, index) => {
            if (row.initialcosts === false) {
                rows[index].initialcostsLocalized = t('no');
            } else if (row.initialcosts === true) {
                rows[index].initialcostsLocalized = t('yes');
            }
        });

        const tableMessages = {
            noData: t('table_nodata')
        };

        const exportTable = () => {
            this.exportRef.current.exportGrid();
        }

        const onExportSave = (wb) => {
            wb._worksheets[1].views.length = 1;
            wb._worksheets[1]._rows.length = this.state.exportRows.length + 1;

            wb.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), 'hostinglist.xlsx');
            });
        }

        const RowDetail = ({row}) => {
            return (
                <div className={classes.detailRow}>
                    <MaterialUIGrid container>
                        <MaterialUIGrid item xs={6}>
                            <Typography variant="h6">{t('hostinglist_history_checks')}</Typography>
                            {row.history.length > 0 ? row.history.map((check, index) => (
                                <Typography variant="body1" key={index}>{check.date} - {check.amount}</Typography>
                            )) : <Typography variant="body1">{t('hostinglist_history_nochecks')}</Typography>}
                        </MaterialUIGrid>
                        <MaterialUIGrid item xs={6}>
                            <Typography variant="h6">{t('comments')}</Typography>
                            {row.comments.length > 0 ? row.comments.map((comment, index) => (
                                <Typography variant="body1" key={index}>{comment}</Typography>
                            )) : <Typography variant="body1">{t('hostinglist_history_nocomments')}</Typography>}
                        </MaterialUIGrid>
                    </MaterialUIGrid>
                </div>
            );
        };

        const Root = props => <Grid.Root {...props} style={{height: '100%', maxHeight: 500}}/>;

        const columns = [
            {
                name: 'client',
                title: t('client')
            },
            {
                name: 'domain',
                title: t('domain')
            },
            {
                name: 'subscription',
                title: t('subscription')
            },
            {
                name: 'initialcostsLocalized',
                title: t('initialcosts')
            },
            {
                name: 'costs',
                title: t('costs')
            },
            {
                name: 'start',
                title: t('begin')
            },
            {
                name: 'go_live',
                title: t('go_live')
            },
            {
                name: 'canceled',
                title: t('canceled')
            },
            {
                name: 'last_check',
                title: t('last_check')
            }
        ];

        return (
            <div>
                <div>
                    <div className={classes.filterSearchGroup}>
                        <TextField
                            className={classes.searchField}
                            size="small"
                            value={this.state.searchValue}
                            onChange={(e) => this.handleSearch(e.target.value)}
                            placeholder={t('search')}
                            InputProps={{
                                className: classes.searchField
                            }}
                        />
                        <div>
                            <Button className={classes.exportButton} startIcon={<SaveIcon/>} variant="contained"
                                    color="primary" disabled={this.state.exportRows.length < 1} onClick={() => exportTable()}>Export</Button>
                            <Button startIcon={<FilterListIcon/>} variant="contained" color="primary"
                                    onClick={() => this.setState({filterAccExpanded: !this.state.filterAccExpanded})}>Filter</Button>
                        </div>
                    </div>
                    <div className={classes.filterAcc}>
                        <Accordion expanded={this.state.filterAccExpanded}
                                   onChange={() => this.setState({filterAccExpanded: !this.state.filterAccExpanded})}>
                            <AccordionSummary style={{display: 'none'}}></AccordionSummary>
                            <AccordionDetails>
                                <MaterialUIGrid container spacing={2}>
                                    <MaterialUIGrid item xs={12}>
                                        <Typography variant="h5">{t('filter_all_entries')}</Typography>
                                    </MaterialUIGrid>
                                    <MaterialUIGrid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                        <FormControl className={classes.filterSelectInput}>
                                            <InputLabel
                                                id="client-filter-label">{t('client')}</InputLabel>
                                            <Select
                                                labelId="client-filter-label"
                                                id="client-filter"
                                                value={this.state.activeClientFilter}
                                                onChange={(e) => this.handleFilterSelection(e.target.value, 'client')}
                                            >
                                                {this.state.allClients.map((client, index) => (
                                                    <MenuItem key={index} value={client}>{client}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <IconButton className={classes.filterSelectResetButton}
                                                    onClick={() => this.handleFilterSelection('', 'client')}
                                                    color="primary" aria-label="Reset Filter"
                                                    component="span">
                                            <CloseIcon/>
                                        </IconButton>
                                    </MaterialUIGrid>
                                    <MaterialUIGrid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                        <FormControl className={classes.filterSelectInput}>
                                            <InputLabel
                                                id="domain-filter-label">{t('domain')}</InputLabel>
                                            <Select
                                                labelId="domain-filter-label"
                                                id="domain-filter"
                                                value={this.state.activeDomainsFilter}
                                                onChange={(e) => this.handleFilterSelection(e.target.value, 'domain')}
                                            >
                                                {this.state.allDomains.map((domain, index) => (
                                                    <MenuItem key={index} value={domain}>{domain}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <IconButton className={classes.filterSelectResetButton}
                                                    onClick={() => this.handleFilterSelection('', 'domain')}
                                                    color="primary" aria-label="Reset Filter" component="span">
                                            <CloseIcon/>
                                        </IconButton>
                                    </MaterialUIGrid>
                                    <MaterialUIGrid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                        <FormControl className={classes.filterSelectInput}>
                                            <InputLabel
                                                id="subscription-filter-label">{t('subscription')}</InputLabel>
                                            <Select
                                                labelId="subscription-filter-label"
                                                id="subscription-filter"
                                                value={this.state.activeSubscriptionsFilter}
                                                onChange={(e) => this.handleFilterSelection(e.target.value, 'subscription')}
                                            >
                                                {this.state.allSubscriptions.map((subscription, index) => (
                                                    <MenuItem key={index}
                                                              value={subscription}>{subscription}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <IconButton className={classes.filterSelectResetButton}
                                                    onClick={() => this.handleFilterSelection('', 'subscription')}
                                                    color="primary" aria-label="Reset Filter" component="span">
                                            <CloseIcon/>
                                        </IconButton>
                                    </MaterialUIGrid>
                                    <MaterialUIGrid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                        <FormControl>
                                            <FormControlLabel className={classes.filterCheckboxInput}
                                                              control={<Checkbox
                                                                  checked={this.state.activeInitalCosts}
                                                                  onChange={(e) => this.handleFilterSelection(!this.state.activeInitalCosts, 'initialcosts')}
                                                                  name={t('initialcosts')}/>}
                                                              label={t('initialcosts')}
                                            />
                                        </FormControl>
                                    </MaterialUIGrid>
                                </MaterialUIGrid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <Paper>
                    <Grid
                        rows={rows}
                        columns={columns}
                        rootComponent={Root}
                    >
                        <RowDetailState
                            defaultExpandedRowIds={[]}
                        />
                        <SortingState
                            defaultSorting={[{columnName: 'client', direction: 'asc'}]}
                        />
                        <SelectionState
                            selection={this.state.selection}
                            onSelectionChange={(selection) => this.handleSelection(selection)}
                        />
                        <IntegratedSelection/>
                        <IntegratedSorting/>
                        <VirtualTable height="auto" messages={tableMessages}/>
                        <TableHeaderRow showSortingControls/>
                        <TableSelection showSelectAll/>
                        <TableRowDetail contentComponent={RowDetail}/>
                    </Grid>
                    <GridExporter
                        ref={this.exportRef}
                        rows={rows}
                        columns={columns}
                        onSave={onExportSave}
                    />
                </Paper>
            </div>
        );
    }
}

export default compose(
    connect(),
    withStyles(useStyles),
    withTranslation(),
)(withRouter(HostingListTable));
