import React from 'react';
import {withStyles} from '@material-ui/styles';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Pie} from 'react-chartjs-2';


const useStyles = (theme) => ({
    headerDataCell: {
        padding: theme.spacing(2)
    },
    headerDataCellHeading: {
        marginBottom: theme.spacing(3)
    }
});

class HostinglistHeaderData extends React.Component {
    render() {
        const {classes} = this.props;
        const {t} = this.props;

        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.headerDataCell}>
                        <Typography className={classes.headerDataCellHeading} variant="h5">{t('total_sales')}</Typography>
                        <Typography variant="h4" align="center">{this.props.totalSales}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.headerDataCell}>
                        <Typography className={classes.headerDataCellHeading} variant="h5">{t('net_sales')}</Typography>
                        <Typography variant="h4" align="center">{this.props.netSales}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.headerDataCell}>
                        <Typography className={classes.headerDataCellHeading} variant="h5">{t('hostinglist_total_servers')}</Typography>
                        <div className={classes.pieWrapper}>
                            <Pie
                                data={this.props.pieData}
                                options={{
                                    legend:{
                                        display:true,
                                        position:'left'
                                    }
                                }}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default compose(
    withStyles(useStyles),
    withTranslation()
)(HostinglistHeaderData);
