const colors = {
    primary: '#ff5c5c',
    secondary: '#ff5c5c',
    success: '#00C853',
    warning: '#FF8A19',
    error: '#B50D0D',
    black: '#000',
    white: '#fff',
    grey100: '#F0F0F0',
    grey200: '#E7E7E7'
}

export default colors;
