export default (baseTheme) => {

    baseTheme.overrides = {
        MuiBottomNavigationAction : {
            root : {
                minWidth: 'auto'
            }
        }
    };

    return baseTheme;
}
