class UserAgent {

    static isIos() {
        return process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    static isAndroid() {
        return process.browser && /(android)/.test(navigator.userAgent);
    }

    static isInStandaloneMode() {
        return ('standalone' in window.navigator) && (window.navigator.standalone);
    }

    static getAppVersion() {
        return process.env.REACT_APP_VERSION || '';
    }

    static getBuildDate() {
        return process.env.REACT_APP_BUILD_TIME || '';
    }

    static getBrowserLanguage() {
        return window.navigator.language;
    }

    static getOS() {
        return window.navigator.platform;
    }
}

export default (UserAgent);
