import React, {Fragment} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {updateSiteTitle} from '../../redux/global/globalAction';
import {withTranslation} from "react-i18next";
import {withStyles} from '@material-ui/styles';
import {mockDataApplicationList} from '../../api/mockData';
import ProjectList from '../utils/application/projectList';

const useStyles = (theme) => ({
    backToAppLink: {
        display: 'block',
        marginBottom: theme.spacing(3)
    },
    projectData: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(4)
    },
    projectDataBodyRow: {
        marginTop: theme.spacing(3)
    },
    projectDataBodyFormRow: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2)
    },
    projectDataCheckbox: {
        padding: 0,
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
        marginLeft: 0,
        marginRight: theme.spacing(1),
        cursor: 'initial',
        pointerEvents: 'none'
    }
});

class Project extends React.Component {

    constructor() {
        super();

        this.state = {
            project: null,
            app: null
        }
    }

    getProject(appId, projectId) {
        const app = mockDataApplicationList.find(app => app.id === appId);
        const project = app.projects.find(project => project.id === projectId);

        this.setState({project: project, app: app});
    }

    getOtherProjects(projectId) {
        let otherProjects = [];

        this.state.app.projects.map(project => {
            if (project.id !== projectId) {
                otherProjects.push(project);
            }
            return '';
        });

        return otherProjects;
    }

    setSiteTitle(title) {
        this.props.updateSiteTitle(title);
    }

    componentDidMount() {
        const appId = this.props.match.params.appId;
        const projectId = this.props.match.params.projectId;

        if (!this.state.project) {
            this.getProject(appId, projectId);
        } else {
            this.setSiteTitle(this.state.project.name);
        }
    }

    componentDidUpdate() {
        const appId = this.props.match.params.appId;
        const projectId = this.props.match.params.projectId;

        if (!this.state.project || this.state.project.id !== projectId) {
            this.getProject(appId, projectId);
        } else {
            this.setSiteTitle(this.state.project.name);
        }
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;
        const appId = this.props.match.params.appId;
        const projectId = this.props.match.params.projectId;
        const otherProjects = this.state.app ? this.getOtherProjects(projectId) : [];

        return (
            <Grid container className={classes.project}>
                {this.state.project ?
                    <Fragment>
                        <Grid item xs={12} lg={10}>
                            <Link component={RouterLink} to={'/applications/' + this.state.app.id}
                                  className={classes.backToAppLink}>
                                <Typography
                                    variant="body1">{'< ' + t('back_to_application') + ' "' + this.state.app.name + '"'}</Typography>
                            </Link>
                            <Paper className={classes.projectData}>
                                <Typography variant="h4">{this.state.project.name}</Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('gtm')}</strong>
                                            </Typography>
                                            <Typography variant="body1">{this.state.project.gtm ?
                                                this.state.project.gtm
                                                : t('no_gtm')}
                                            </Typography>
                                        </div>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('nameserver')}</strong>
                                            </Typography>
                                            <Typography variant="body1">{this.state.project.nameserver ?
                                                this.state.project.nameserver
                                                : t('no_nameserver')}
                                            </Typography>
                                        </div>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('domain_alias')}</strong>
                                            </Typography>
                                            {this.state.project.domainAlias.length > 0 ?
                                                this.state.project.domainAlias.map(domain => (
                                                    <Typography variant="body1" key={domain}>{domain}</Typography>
                                                ))
                                                : <Typography variant="body1">{t('no_domain_alias')}</Typography>}
                                        </div>
                                        <div className={classes.projectDataBodyFormRow}>
                                            <Checkbox className={classes.projectDataCheckbox}
                                                      checked={this.state.project.ssl} name={t('ssl')}/>
                                            <Typography variant="body1">
                                                <strong>{t('ssl')}</strong>
                                            </Typography>
                                        </div>
                                        <div className={classes.projectDataBodyFormRow}>
                                            <Checkbox className={classes.projectDataCheckbox}
                                                      checked={this.state.project.cookieFirst}
                                                      name={t('cookie_first')}/>
                                            <Typography variant="body1">
                                                <strong>{t('cookie_first')}</strong>
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('contact_person_internal')}</strong>
                                            </Typography>
                                            <Typography
                                                variant="body1">
                                                {this.state.project.contactPersonInternal ?
                                                    this.state.project.contactPersonInternal ?
                                                        this.state.project.contactPersonInternal.name
                                                        : ''
                                                    : this.state.app.contactPerson ? this.state.app.contactPerson
                                                        : t('no_contact_person_internal')
                                                }</Typography>
                                        </div>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('contact_person_external')}</strong>
                                            </Typography>
                                            {Object.keys(this.state.project.contactPersonExternal).length > 0 && this.state.project.contactPersonExternal.constructor === Object ?
                                                <>
                                                    {this.state.project.contactPersonExternal.name ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonExternal.name}</Typography>
                                                        : ''}
                                                    {this.state.project.contactPersonExternal.company ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonExternal.company}</Typography>
                                                        : ''}
                                                    {this.state.project.contactPersonExternal.email ?
                                                        <Link
                                                            href={'mailto:' + this.state.project.contactPersonExternal.email}
                                                            title={this.state.project.contactPersonExternal.email}>
                                                            <Typography
                                                                variant="body1">{this.state.project.contactPersonExternal.email}</Typography>
                                                        </Link>
                                                        : ''}
                                                    {this.state.project.contactPersonExternal.phone ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonExternal.phone}</Typography>
                                                        : ''}
                                                </>
                                                : <Typography
                                                    variant="body1">{t('no_contact_person_external')}</Typography>
                                            }
                                        </div>
                                        <div className={classes.projectDataBodyRow}>
                                            <Typography variant="body1">
                                                <strong>{t('contact_person_technical')}</strong>
                                            </Typography>
                                            {Object.keys(this.state.project.contactPersonTechnical).length > 0 && this.state.project.contactPersonTechnical.constructor === Object ?
                                                <>
                                                    {this.state.project.contactPersonTechnical.name ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonTechnical.name}</Typography>
                                                        : ''}
                                                    {this.state.project.contactPersonTechnical.company ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonTechnical.company}</Typography>
                                                        : ''}
                                                    {this.state.project.contactPersonTechnical.email ?
                                                        <Link
                                                            href={'mailto:' + this.state.project.contactPersonTechnical.email}
                                                            title={this.state.project.contactPersonTechnical.email}>
                                                            <Typography
                                                                variant="body1">{this.state.project.contactPersonTechnical.email}</Typography>
                                                        </Link>
                                                        : ''}
                                                    {this.state.project.contactPersonTechnical.phone ?
                                                        <Typography
                                                            variant="body1">{this.state.project.contactPersonTechnical.phone}</Typography>
                                                        : ''}
                                                </>
                                                : <Typography
                                                    variant="body1">{t('no_contact_person_technical')}</Typography>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <ProjectList title="more_projects_in_app" appId={appId} projects={otherProjects}/>
                    </Fragment>
                    : ''}
            </Grid>
        );
    }

}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle
});

const mapDispatchToProps = {
    updateSiteTitle
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
    withTranslation(),
)(Project);
