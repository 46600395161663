import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {withStyles} from '@material-ui/styles';

const useStyles = (theme) => ({
    projectsInApp: {
        marginTop: theme.spacing(6)
    },
    projectsInAppTitle: {
        marginBottom: theme.spacing(2)
    },
    projectWrapper: {
        display: 'flex',
        flexGrow: 1
    },
    projectLink: {
        display: 'flex',
        width: '100%',

        '&:hover': {
            textDecoration: 'none',

            '& h5': {
                color: theme.palette.primary.main,
                textDecoration: 'underline'
            }
        }
    },
    project: {
        padding: theme.spacing(2),
        width: '100%'
    },
    projectTitle: {
        marginBottom: theme.spacing(2)
    },
    projectSSL: {
        marginBottom: theme.spacing(2)
    },
    projectCookieFirst: {
        marginBottom: theme.spacing(2)
    },
    projectDomainAliasHeader: {
        marginBottom: theme.spacing(1)
    },
    projectDomainAlias: {
        marginBottom: theme.spacing(1)
    }
});

class ProjectList extends React.Component {

    render() {
        const {t} = this.props;
        const {classes} = this.props;

        return (
            <Grid container>
                <Grid item xs={12} lg={10} className={classes.projectsInApp}>
                    <Typography variant="h4"
                                className={classes.projectsInAppTitle}>{t(this.props.title)}</Typography>
                    <Grid container spacing={3}>
                        {this.props.projects.length > 0 ? this.props.projects.map(project => (
                            <Grid item xs={12} md={6} lg={4} key={project.id}
                                  className={classes.projectWrapper}>
                                <Link component={RouterLink}
                                      className={classes.projectLink}
                                      to={'/applications/' + this.props.appId + '/' + project.id}>
                                    <Paper className={classes.project}>
                                        <Typography variant="h5"
                                                    className={classes.projectTitle}>{project.name}</Typography>
                                        {project.ssl ?
                                            <Typography variant="body1"
                                                        className={classes.projectSSL}>
                                                <strong>{t('ssl')}</strong>
                                            </Typography>
                                            : ''}
                                        {project.cookieFirst ?
                                            <Typography variant="body1"
                                                        className={classes.projectCookieFirst}>
                                                <strong>{t('cookie_first')}</strong>
                                            </Typography>
                                            : ''}
                                        <Typography variant="body1"
                                                    className={classes.projectDomainAliasHeader}>
                                            <strong>{t('domain_alias')}</strong>
                                        </Typography>
                                        {project.domainAlias.length > 0 ?
                                            <>
                                                {/* eslint-disable-next-line*/}
                                                {project.domainAlias.map((alias, index) => {
                                                    if (index < 3) {
                                                        return (
                                                            <Typography variant="body1"
                                                                        key={index}
                                                                        className={classes.projectDomainAlias}>
                                                                {alias}
                                                            </Typography>
                                                        )
                                                    }
                                                })}
                                            </>
                                            : <Typography
                                                variant="body1">{t('no_domain_alias')}</Typography>}
                                        {project.domainAlias.length > 3 ?
                                            <Typography variant="body1">
                                                ... {(project.domainAlias.length - 3) > 1 ?
                                                project.domainAlias.length - 3 + ' ' + t('more_alias')
                                                : project.domainAlias.length - 3 + ' ' + t('one_more_alias')}
                                            </Typography> : ''}
                                    </Paper>
                                </Link>
                            </Grid>
                        )) : <Grid item xs={12} className={classes.projectWrapper}>
                                <Typography variant="h6">{t('no_projects_found')}</Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withTranslation(),
)(ProjectList);
