import colors from './colors';

export const themeName = 'default theme';

export const typography = {
    fontFamily: "'Cabin', 'Arial', sans-serif",
    htmlFontSize: 16,
    h6: {
        fontSize: '16px',
        fontWeight: 700,
    },
};

export const palette = {
    type: 'light',
    primary: {main: colors.primary},
    secondary: {main: colors.secondary, contrastText: colors.white},
    background: {default: colors.white},
};

export const shape = {
};

export const props = {
};
