import SideNavigationOpen from './sideNavigation/SideNavigationOpen';
import HeaderButtons from './sideNavigation/HeaderButtons';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import {withStyles} from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

const useStyles = (theme) => ({
    appBarShift: {
        width: `0`,
        marginLeft: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 400px)',
            marginLeft: 400,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    sideNavigationGroup: {
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: 'inherit',
    },
    buttonGroup: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    searchFieldWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    logoLink: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    logo: {
        width: 30,
        height: 'auto'
    },
    searchField: {
        width: 200,

        [theme.breakpoints.up('md')]: {
            width: 250
        },

        [theme.breakpoints.up('lg')]: {
            width: 350
        }
    },
    searchFieldIcon: {
        color: theme.palette.common.white
    },
    searchFieldInput: {
        color: theme.palette.common.white,

        '&:hover:not(.Mui-disabled)::before, &:before': {
            borderColor: theme.palette.common.white
        },

        '&:after': {
            borderBottom: '0'
        }
    }
});

class AppHeader extends React.Component {

    render() {
        const {t} = this.props;
        const {classes} = this.props;

        return (
            <div className={`${classes.root} ${(this.props.sideNavOpen ? classes.appBarShift : '')}`}>
                <AppBar position="static">
                    <Toolbar>
                        <div className={classes.flexContainer}>
                            <div className={classes.sideNavigationGroup}>
                                <SideNavigationOpen>
                                    <MenuIcon/>
                                </SideNavigationOpen>

                                <div className={classes.growingContainer}>
                                    <Typography variant="body1">
                                        {this.props.siteTitle}
                                    </Typography>
                                </div>
                            </div>

                            <div className={classes.searchFieldWrapper}>
                                <Link component={RouterLink} to="/dashboard" className={classes.logoLink}>
                                    <img className={classes.logo} src={process.env.PUBLIC_URL + '/logo.svg'} alt={t('pwa_title')} />
                                </Link>
                                <TextField
                                    className={classes.searchField}
                                    size="small"
                                    placeholder={t('search')}
                                    InputProps={{
                                        startAdornment: <InputAdornment className={classes.searchFieldIcon}
                                                                        position="start">
                                            <Search/>
                                        </InputAdornment>,
                                        className: classes.searchFieldInput
                                    }}
                                />
                            </div>

                            <div className={classes.buttonGroup}>
                                <HeaderButtons color="secondary" />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle,
    sideNavOpen: state.global.sideNavOpen
});

export default compose(
    connect(
        mapStateToProps
    ),
    withTranslation(),
    withStyles(useStyles),
)(AppHeader);
