export const mockDataGoLive = [
    {
        appName: 'Hotel-Neuschönstadt',
        client: 'Hotel-Neuschönstadt',
        url: 'hotel-neuschoenstadt.ch',
        software: 'PIMCORE v6',
        projectStart: '14.01.2021',
        onlineSince: '29.01.2021'
    },
    {
        appName: 'Die Sitter',
        client: 'Sitterkommission',
        url: 'diesitter.ch',
        software: 'TYPO3 v8',
        projectStart: '01.08.2016',
        onlineSince: '27.08.2020'
    },
    {
        appName: 'Hotel-Neuschönstadt',
        client: 'Hotel-Neuschönstadt',
        url: 'hotel-neuschoenstadt.ch',
        software: 'PIMCORE v6',
        projectStart: '14.01.2021',
        onlineSince: '29.01.2021'
    },
    {
        appName: 'Die Sitter',
        client: 'Sitterkommission',
        url: 'diesitter.ch',
        software: 'TYPO3 v8',
        projectStart: '01.08.2016',
        onlineSince: '27.08.2020'
    },
    {
        appName: 'Hotel-Neuschönstadt',
        client: 'Hotel-Neuschönstadt',
        url: 'hotel-neuschoenstadt.ch',
        software: 'PIMCORE v6',
        projectStart: '14.01.2021',
        onlineSince: '29.01.2021'
    }
]

export const mockDataGoLiveHeaders = [
    {
        name: 'table_application_name',
        alignment: 'left'
    },
    {
        name: 'table_client_name',
        alignment: 'left'
    },
    {
        name: 'table_url',
        alignment: 'left'
    },
    {
        name: 'table_software',
        alignment: 'left'
    },
    {
        name: 'table_project_start',
        alignment: 'left'
    },
    {
        name: 'table_online_since',
        alignment: 'left'
    }
]

export const mockDataDevelopingProjects = [
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        software: 'PIMCORE v6',
        projectStart: '03.02.2021'
    },
    {
        appName: 'Fit-Care',
        client: 'Fit-Care AG',
        url: 'staging.fit-care.ch',
        software: 'TYPO3 v10',
        projectStart: '01.02.2021'
    },
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        software: 'PIMCORE v6',
        projectStart: '03.02.2021'
    },
    {
        appName: 'Fit-Care',
        client: 'Fit-Care AG',
        url: 'staging.fit-care.ch',
        software: 'TYPO3 v10',
        projectStart: '01.02.2021'
    },
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        software: 'PIMCORE v6',
        projectStart: '03.02.2021'
    }
]

export const mockDataDevelopingProjectsHeaders = [
    {
        name: 'table_application_name',
        alignment: 'left'
    },
    {
        name: 'table_client_name',
        alignment: 'left'
    },
    {
        name: 'table_url',
        alignment: 'left'
    },
    {
        name: 'table_software',
        alignment: 'left'
    },
    {
        name: 'table_project_start',
        alignment: 'left'
    }
]

export const mockDataHighStorageUsageProjects = [
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        storageDB: '130 MB',
        storageProject: '23.5 GB'
    },
    {
        appName: 'Fit-Care',
        client: 'Fit-Care AG',
        url: 'staging.fit-care.ch',
        storageDB: '246 MB',
        storageProject: '12.4 GB'
    },
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        storageDB: '130 MB',
        storageProject: '23.5 GB'
    },
    {
        appName: 'Fit-Care',
        client: 'Fit-Care AG',
        url: 'staging.fit-care.ch',
        storageDB: '246 MB',
        storageProject: '12.4 GB'
    },
    {
        appName: 'Clean-Fix',
        client: 'Clean-Fix Reinigungssysteme AG',
        url: 'production.clean-fix.ch',
        storageDB: '130 MB',
        storageProject: '23.5 GB'
    }
]

export const mockDataHighStorageUsageProjectsHeaders = [
    {
        name: 'table_application_name',
        alignment: 'left'
    },
    {
        name: 'table_client_name',
        alignment: 'left'
    },
    {
        name: 'table_url',
        alignment: 'left'
    },
    {
        name: 'table_dbstorage',
        alignment: 'left'
    },
    {
        name: 'table_projectstorage',
        alignment: 'left'
    }
]

export const mockDataPHPChart = {
    labels: ['PHP 5.2', 'PHP 5.4', 'PHP 7.2', 'PHP 7.4'],
    datasets: [
        {
            label: 'PHP',
            backgroundColor: [
                '#ff5c5c',
                '#ffd85c',
                '#5c74ff',
                '#5cff92'
            ],
            data: [4, 2, 59, 75]
        }
    ]
}

export const mockDataPimcoreChart = {
    labels: ['PIMCORE v4', 'PIMCORE v5', 'PIMCORE v6'],
    datasets: [
        {
            label: 'PIMCORE',
            backgroundColor: [
                '#ff5c5c',
                '#ffd85c',
                '#5cff92',
            ],
            data: [34, 65, 85]
        }
    ]
}

export const mockDataTypo3Chart = {
    labels: ['TYPO3 v6', 'TYPO3 v7', 'TYPO3 v8', 'TYPO3 v9', 'TYPO3 v10'],
    datasets: [
        {
            label: 'TYPO3',
            backgroundColor: [
                '#ff5c5c',
                '#ffd85c',
                '#ffc35c',
                '#5c74ff',
                '#5cff92',
            ],
            data: [32, 65, 43, 16, 8]
        }
    ]
}

export const mockDataMonitoringReports = [
    {
        report: 'Domain production.seniorlabor.com was not found by the server',
        reportReason: 'DNS production.seniorlabor.ch',
        type: 'error'
    },
    {
        report: 'Domain production.seniorlabor.com was not found by the server',
        reportReason: 'DNS production.seniorlabor.ch',
        type: 'warning'
    },
    {
        report: 'Domain production.seniorlabor.com was not found by the server',
        reportReason: 'DNS production.seniorlabor.ch',
        type: 'info'
    }
]

export const mockDataHostingList = {
    totalSales: 'CHF 97`039',
    netSales: 'CHF 64`209',
    pieData: {
        labels: ['Hostpoint', 'NINE', 'Metanet'],
        datasets: [
            {
                label: 'Server',
                backgroundColor: [
                    '#ff5c5c',
                    '#ffd85c',
                    '#5cff92',
                ],
                data: [42, 69, 75]
            }
        ]
    },
    tableContent: [
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        },
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        },
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        },
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        },
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        },
        {
            client: 'Bücherladen Appenzell',
            domain: 'buecherladen-appenzell.ch',
            subscription: 'TYPO3 Basic',
            initialcosts: true,
            costs: '19.50 CHF',
            start: '19.12.2014',
            go_live: '12.03.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Nicola Spirig',
            domain: 'nicola-spirig.ch',
            subscription: 'TYPO3 Sport',
            initialcosts: false,
            costs: '19.50 EUR',
            start: '19.12.2014',
            go_live: '12.05.2015',
            canceled: '',
            last_check: '05.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: [
                'Ist ein Test-Projekt, wird nicht verrechnet'
            ]
        },
        {
            client: 'Appenzellerland Tourismus',
            domain: 'appenzell.ch',
            subscription: 'TYPO3 AppTou',
            initialcosts: false,
            costs: '79.50 EUR',
            start: '26.06.2015',
            go_live: '18.09.2015',
            canceled: '',
            last_check: '02.04.2021',
            history: [
                {
                    date: '05.04.2021',
                    amount: '35.90 CHF'
                },
                {
                    date: '05.03.2021',
                    amount: '12.90 CHF'
                },
                {
                    date: '05.02.2021',
                    amount: '23.90 EUR'
                }
            ],
            comments: []
        }]
};

export const mockDataApplicationList = [
    {
        id: '1453',
        name: 'pimcore-egk',
        applicationType: 'pimcore',
        hosting: 'DC Standard',
        server: 'dachcom9.nine.ch',
        phpVersion: '7.4',
        appVersion: '6.8.4',
        sla: true,
        slaVersion: 'Premium / PCM1029',
        contactPerson: 'Uli Wolter',
        projectStart: '08.01.2021',
        projects: [
            {
                id: '884521',
                name: 'www.egk.ch',
                ssl: true,
                cookieFirst: false,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.egk.com'
                ]
            },
            {
                id: '962214',
                name: 'www.sinnieren-flanieren.ch',
                ssl: true,
                cookieFirst: false,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.sinnieren-flanieren.com'
                ]
            },
            {
                id: '162358',
                name: 'www.fondation-sne.ch',
                ssl: true,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.fondation-sne.com',
                    'www.sne.com'
                ]
            }
        ]
    },
    {
        id: '5830',
        name: 'typo3-sob',
        applicationType: 'typo3',
        hosting: 'TYPO3 XYZ',
        server: 'jupidu.hostpoint.ch',
        phpVersion: '7.4',
        appVersion: '9.5 LTS',
        sla: true,
        slaVersion: 'Premium / PCM1029',
        contactPerson: 'Manuel Schmidhauser',
        projectStart: '12.10.2020',
        projects: [
            {
                id: '568911',
                name: 'www.sob.ch',
                ssl: true,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.sob.com',
                    'www.sued-ost-bahnen.com',
                    'www.sued-ost-bahnen.ch'
                ]
            }
        ]
    },
    {
        id: '2375',
        name: 'pimcore-neupack',
        applicationType: 'pimcore',
        hosting: 'DC Standard',
        server: 'dachcom12.nine.ch',
        phpVersion: '7.4',
        appVersion: '6.8.2',
        sla: false,
        contactPerson: 'Philip Vivas',
        projectStart: '30.06.2018',
        projects: [
            {
                id: '789665',
                name: 'www.neupack.ch',
                ssl: true,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.neupack.com'
                ]
            }
        ]
    },
    {
        id: '5614',
        name: 'typo3-stark',
        applicationType: 'typo3',
        hosting: 'TYPO3 XYZ',
        server: 'stark.opsone.ch',
        phpVersion: '7.4',
        appVersion: '9.5 LTS',
        sla: false,
        contactPerson: 'Manuel Schmidhauser',
        projectStart: '01.03.2019',
        projects: [
            {
                id: '152366',
                name: 'www.stark.ch',
                ssl: true,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.stark-holz.ch',
                    'shop.stark.ch'
                ]
            },
            {
                id: '447215',
                name: 'www.holzplatten.ch',
                ssl: true,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.holzplatten-holz.ch',
                    'www.holzplatten-holz1.ch',
                    'www.holzplatten-holz2.ch',
                    'www.holzplatten-holz3.ch',
                    'shop.holzplatten.ch'
                ]
            },
            {
                id: '989546',
                name: 'www.roser-swiss.com',
                ssl: false,
                cookieFirst: true,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.roser-holz.ch',
                    'shop.roser-swiss.ch'
                ]
            },
            {
                id: '636358',
                name: 'www.kueng-platten.ch',
                ssl: true,
                cookieFirst: false,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {
                    name: 'Peter Schmid',
                    company: 'Test Firma',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                domainAlias: [
                    'www.kueng-holz.ch',
                    'shop.kueng-platten.ch'
                ]
            },
            {
                id: '751234',
                name: 'www.anliker.com',
                ssl: false,
                cookieFirst: false,
                gtm: 'GTM-475890',
                contactPersonExternal: {
                    name: 'Lukas Alt',
                    email: 'test@test.ch',
                    phone: '+41 79 123 33 21'
                },
                contactPersonTechnical: {},
                domainAlias: []
            }
        ]
    },
]