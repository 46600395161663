import React, {Fragment} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {connect} from 'react-redux';
import SideNavigationClose from './SideNavigationClose';
import HeaderButtons from './HeaderButtons';
import {updateSideNavState} from '../../../redux/global/globalAction';
import {withStyles} from '@material-ui/styles';
import {groupMain} from '../../../config/routeItems';
import {Link} from 'react-router-dom';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = (theme) => ({
    sideNavigation: {
        zIndex: 999
    },
    fullList: {
        width: '100vw',

        [theme.breakpoints.up('md')]: {
            width: '400px',
        },
    },
    closeNavButton: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttonGroup: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            margin: theme.spacing(1)
        }
    }
});

class AppSideNavigation extends React.Component {

    setSideNavState(event, state = false) {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.props.updateSideNavState(state);
    };

    handleListItemClick = () => () => {
        this.setSideNavState(null, false);
    };

    getListItems() {
        const {t} = this.props;

        return (
            <Fragment>
                {groupMain.map((item) => (
                    <ListItem
                        button
                        key={item.key}
                        onClick={this.handleListItemClick()}
                        component={Link}
                        to={item.url()}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={t(item.label)}/>
                    </ListItem>
                ))}
            </Fragment>
        );
    }

    render() {
        const {classes} = this.props;

        return (
            <Drawer
                variant="persistent"
                anchor="left"
                className={classes.sideNavigation}
                open={this.props.sideNavOpen}
                onClose={(event) => this.setSideNavState(event, false)}
            >
                <div className={classes.closeNavButton}>
                    <SideNavigationClose>
                        <ChevronLeftIcon/>
                    </SideNavigationClose>
                </div>
                <div
                    className={classes.fullList}
                    role="presentation"
                >
                    <Divider/>
                    <List>
                        {this.getListItems()}
                    </List>
                    <Divider/>
                </div>
                <div className={classes.buttonGroup}>
                    <HeaderButtons color="primary" />
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = state => ({
    sideNavOpen: state.global.sideNavOpen,
});

const mapDispatchToProps = {
    updateSideNavState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withStyles(useStyles),
)(AppSideNavigation);
