import React, {Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import Dashboard from '../components/pages/dashboard';
import HomeIcon from '@material-ui/icons/Home';
import HostingList from '../components/pages/hostinglist';
import Dns from '@material-ui/icons/Dns';
import Applications from '../components/pages/applications';
import Application from '../components/pages/application';
import Project from '../components/pages/project';
import AppsIcon from '@material-ui/icons/Apps';

export const routeHome = {
    'key': 'dashboard',
    'label': 'route_dashboard',
    'url': () => '/dashboard',
    'icon': <HomeIcon/>,
    'exact': true,
    'component': (props = {}) => <Dashboard {...props} />,
};

export const routeHostingList = {
    'key': 'hostinglist',
    'label': 'route_hostinglist',
    'url': () => '/hostinglist',
    'icon': <Dns/>,
    'exact': true,
    'component': (props = {}) => <HostingList {...props} />,
};

export const routeApplications = {
    'key': 'applications',
    'label': 'route_applications',
    'url': () => '/applications',
    'icon': <AppsIcon/>,
    'exact': true,
    'component': (props = {}) => <Applications {...props} />,
};


export const routeApplication = {
    'key': 'application',
    'label': 'route_application',
    'url': () => '/applications/:appId',
    'icon': <AppsIcon/>,
    'exact': true,
    'component': (props = {}) => <Application {...props} />,
};


export const routeProject = {
    'key': 'project',
    'label': 'route_project',
    'url': () => '/applications/:appId/:projectId',
    'icon': <AppsIcon/>,
    'exact': true,
    'component': (props = {}) => <Project {...props} />,
};

export const routeNoMatchPage = {
    'key': 'noMatchPage',
    'label': 'route_no_match_page',
    'url': () => '',
    'icon': <Fragment/>,
    'exact': false,
    'component': (props = {}) => <Redirect {...props} to="/dashboard" />,
};

export const groupMain = [routeHome, routeHostingList, routeApplications];
export const allRoutes = [].concat(groupMain, routeApplication, routeProject, routeNoMatchPage);
