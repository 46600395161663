import React from 'react';
import {connect} from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {updateSideNavState} from '../../../redux/global/globalAction';
import {withStyles} from '@material-ui/styles';
import {compose} from 'redux';


const useStyles = (theme) => ({
    open: {
        display: 'block'
    },
    closed: {
        display: 'none'
    }
});

class SideNavigationOpen extends React.Component {

    handleSideNavToggleClick() {
        this.props.updateSideNavState(true);
    }

    render() {
        const {classes} = this.props;
        return (
            <IconButton edge="start" className={this.props.sideNavOpen ? classes.closed : classes.open} color="inherit" aria-label="menu" onClick={() => {
                this.handleSideNavToggleClick();
            }}>
                <MenuIcon/>
            </IconButton>
        );
    }
}

const mapStateToProps = state => ({
    sideNavOpen: state.global.sideNavOpen,
});

const mapDispatchToProps = {
    updateSideNavState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
)(SideNavigationOpen);
