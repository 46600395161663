import React, {Fragment} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Person from '@material-ui/icons/Person';
import Description from '@material-ui/icons/Description';
import Notifications from '@material-ui/icons/Notifications';
import {withStyles} from '@material-ui/styles';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

const useStyles = (theme) => ({
    button: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    buttonIconPrimary: {
        width: 30,
        height: 30,
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    buttonIconSecondary: {
        width: 30,
        height: 30,
        background: theme.palette.common.white,
        color: theme.palette.primary.main
    }
});

class HeaderButtons extends React.Component {
    render() {
        const {t} = this.props;
        const {classes} = this.props;

        return (
            <Fragment>
                <div className={classes.button}>
                    <Avatar alt={t('button_user')} className={this.props.color === 'primary' ? classes.buttonIconPrimary : classes.buttonIconSecondary}>
                        <Person/>
                    </Avatar>
                </div>
                <div className={classes.button}>
                    <Avatar alt={t('button_pdf')} className={this.props.color === 'primary' ? classes.buttonIconPrimary : classes.buttonIconSecondary}>
                        <Description/>
                    </Avatar>
                </div>
                <div className={classes.button}>
                    <Avatar alt={t('button_notification')} className={this.props.color === 'primary' ? classes.buttonIconPrimary : classes.buttonIconSecondary}>
                        <Notifications/>
                    </Avatar>
                </div>
            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(useStyles),
)(HeaderButtons);
