import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {updateSiteTitle} from '../../redux/global/globalAction';
import {mockDataApplicationList} from '../../api/mockData';
import {withTranslation} from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import {withStyles} from '@material-ui/styles';
import StarIcon from '@material-ui/icons/Star';
import ProjectList from '../utils/application/projectList';

const useStyles = (theme) => ({
    applicationData: {
        padding: theme.spacing(3)
    },
    applicationDataHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    applicationDataHeaderSLA: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginLeft: theme.spacing(10),
        }
    },
    applicationDataHeaderSLAIcon: {
        marginRight: theme.spacing(1)
    },
    applicationDataHeaderImage: {
        width: 60,
        height: 60
    },
    applicationDataHeaderContent: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    applicationDataBodyRow: {
        marginTop: theme.spacing(2)
    }
});

class Application extends React.Component {

    constructor() {
        super();

        this.state = {
            app: null
        }
    }


    componentDidMount() {
        const appId = this.props.match.params.appId;

        if (!this.state.app) {
            this.getApp(appId);
        } else {
            this.props.updateSiteTitle(this.state.app.name);
        }
    }

    componentDidUpdate() {
        const appId = this.props.match.params.appId;

        if (!this.state.app || this.state.app.id !== appId) {
            this.getApp(appId);
        } else {
            this.props.updateSiteTitle(this.state.app.name);
        }
    }

    getApp(appId) {
        this.setState({app: mockDataApplicationList.find(app => app.id === appId)});
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;
        const appId = this.props.match.params.appId;

        return (
            <Grid container>
                {this.state.app ?
                    <Fragment>
                        <Grid item xs={12} lg={10}>
                            <Paper className={classes.applicationData}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.applicationDataHeader}>
                                        <div className={classes.applicationDataHeaderContent}>
                                            <Typography variant="h4">{this.state.app.name}</Typography>
                                            {this.state.app.sla ?
                                                <div className={classes.applicationDataHeaderSLA}>
                                                    <StarIcon
                                                        className={classes.applicationDataHeaderSLAIcon}/><Typography
                                                    variant="body1">{this.state.app.slaVersion}</Typography>
                                                </div> : ''}
                                        </div>
                                        <img className={classes.applicationDataHeaderImage}
                                             src={process.env.PUBLIC_URL + '/applications-logos/software/' + this.state.app.applicationType + '.svg'}
                                             alt={this.state.app.applicationType}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('hosting')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.hosting}</Typography>
                                        </div>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('server')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.server}</Typography>
                                        </div>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('php_version')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.phpVersion}</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('app_version')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.appVersion}</Typography>
                                        </div>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('contact_person_internal')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.contactPerson}</Typography>
                                        </div>
                                        <div className={classes.applicationDataBodyRow}>
                                            <Typography
                                                variant="body1"><strong>{t('project_start')}</strong></Typography>
                                            <Typography variant="body1">{this.state.app.projectStart}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <ProjectList title="projects_in_app" projects={this.state.app.projects} appId={appId}/>
                    </Fragment>
                    : ''}
            </Grid>
        );
    }
}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle
});

const mapDispatchToProps = {
    updateSiteTitle,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
    withTranslation(),
)(Application);
