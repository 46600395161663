import React from 'react';
import AppHeader from './layout/appHeader';
import AppSideNavigation from './layout/sideNavigation/AppSideNavigation';
import {withStyles} from '@material-ui/styles';
import AppBody from './layout/appBody';
import PwaInstallMessage from './base/pwaInstallMessage';
import {compose} from 'redux';
import {connect} from "react-redux";

const useStyles = (theme) => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    body: {
        flex: '1',
        overflow: 'auto',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(10),
    },
    navOpenBody: {
        width: `0`,
        marginLeft: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

        [theme.breakpoints.up('md')]: {
            width: 'calc(100% - 400px)',
            marginLeft: 400,
        },
    },
    appHeader: {
        position: 'fixed',
        width: '100%',
        zIndex: 99
    }
});

class App extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>

                <AppSideNavigation/>
                <PwaInstallMessage/>

                <div className={classes.appHeader}>
                    <AppHeader/>
                </div>

                <div className={`${classes.body} ${(this.props.sideNavOpen ? classes.navOpenBody : '')}`}>
                    <AppBody/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sideNavOpen: state.global.sideNavOpen
});

export default compose(
    connect(
        mapStateToProps
    ),
    withStyles(useStyles),
)(App);
