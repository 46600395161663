import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {updateSiteTitle} from "../../redux/global/globalAction";
import {withTranslation} from "react-i18next";
import DashboardGoLiveTable from '../utils/dashboard/dashboardGoLiveTable';
import DashboardDevelopingTable from '../utils/dashboard/dashboardDevelopingTable';
import DashboardHighStorageUsageTable from '../utils/dashboard/dashboardHighStorageUsageTable';
import {withStyles} from '@material-ui/styles';
import {Pie} from 'react-chartjs-2';

import {
    mockDataGoLive, mockDataGoLiveHeaders,
    mockDataDevelopingProjects, mockDataDevelopingProjectsHeaders,
    mockDataHighStorageUsageProjects, mockDataHighStorageUsageProjectsHeaders,
    mockDataPHPChart, mockDataPimcoreChart, mockDataTypo3Chart,
    mockDataMonitoringReports
} from '../../api/mockData';

const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2)
    },
    tablePaper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    tableHeader: {
        marginBottom: theme.spacing(2)
    },
    tabHeader: {
        marginBottom: theme.spacing(2)
    },
    pieWrapper: {
        marginBottom: theme.spacing(3)
    },
    reportAlert: {
        marginBottom: theme.spacing(2),

        '&:last-child': {
            marginBottom: 0
        }
    }
});

function TabPanel(props) {
    const { children, value, index, data, title, classes, t, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`technology-tabpanel-${index}`}
            aria-labelledby={`technology-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>

                    <div className={classes.pieWrapper}>
                        <Pie
                            data={data}
                            options={{
                                legend:{
                                    display:true,
                                    position:'right'
                                }
                            }}
                        />
                    </div>
                    <Typography variant="h5" align="center">{t(title)}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            monitoringReports: mockDataMonitoringReports
        };
    }


    handleTabChange = (event, newValue) => {
        this.setState({tabValue: newValue});
    }

    handleRemoveReport = (index) => {
        let reports = this.state.monitoringReports;
        reports.splice(index,1);

        this.setState({monitoringReports: reports});
    }

    componentDidMount() {
        this.props.updateSiteTitle(this.props.t('route_dashboard'));
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;

        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={8}>
                        <Paper className={classes.tablePaper}>
                            <Typography variant="h5" align="left" className={classes.tableHeader}>{t('dashboard_table_goLiveHeading')}</Typography>
                            <DashboardGoLiveTable rows={mockDataGoLive} rowHeaders={mockDataGoLiveHeaders}/>
                        </Paper>
                        <Paper className={classes.tablePaper}>
                            <Typography variant="h5" align="left" className={classes.tableHeader}>{t('dashboard_table_developingProjects')}</Typography>
                            <DashboardDevelopingTable rows={mockDataDevelopingProjects} rowHeaders={mockDataDevelopingProjectsHeaders}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Paper className={classes.tabHeader}>
                                <Tabs value={this.state.tabValue}
                                      onChange={this.handleTabChange}
                                      aria-label={t('dashboard_tab_arialabel')}
                                      variant="fullWidth">
                                    <Tab label={t('php')} {...a11yProps(0)} />
                                    <Tab label={t('pimcore')} {...a11yProps(1)} />
                                    <Tab label={t('typo3')} {...a11yProps(2)} />
                                </Tabs>
                        </Paper>
                        <Paper>
                            <TabPanel value={this.state.tabValue}
                                      index={0}
                                      title="php_versions"
                                      classes={classes}
                                      t={t}
                                      data={mockDataPHPChart}/>
                            <TabPanel value={this.state.tabValue}
                                      index={1}
                                      title="pimcore_versions"
                                      classes={classes}
                                      t={t}
                                      data={mockDataPimcoreChart}/>

                            <TabPanel value={this.state.tabValue}
                                      index={2}
                                      title="typo3_versions"
                                      classes={classes}
                                      t={t}
                                      data={mockDataTypo3Chart}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.tablePaper}>
                            <Typography variant="h5" align="left" className={classes.tableHeader}>{t('dashboard_high_storage_usage')}</Typography>
                            <DashboardHighStorageUsageTable rows={mockDataHighStorageUsageProjects} rowHeaders={mockDataHighStorageUsageProjectsHeaders}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper className={classes.tablePaper}>
                            <Typography variant="h5" align="left" className={classes.tableHeader}>{t('dashboard_monitoring_heading')}</Typography>
                            {this.state.monitoringReports.length > 0 ? this.state.monitoringReports.map((report, index) => (
                                <Alert severity={report.type} onClose={() => this.handleRemoveReport(index)} key={index} className={classes.reportAlert}>
                                    <AlertTitle>{report.reportReason}</AlertTitle>
                                    {report.report}
                                </Alert>
                            )) : <Typography variant="h6" align="left">{t('dashboard_monitoring_noreports')}</Typography>}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle,
});

const mapDispatchToProps = {
    updateSiteTitle,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
    withTranslation(),
)(Dashboard);
