import React from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {updateSiteTitle} from '../../redux/global/globalAction';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@material-ui/styles';
import {mockDataApplicationList} from '../../api/mockData';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import Zoom from '@material-ui/core/Zoom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import StarIcon from '@material-ui/icons/Star';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = (theme) => ({
    applications: {},
    filterSearchGroup: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    searchField: {
        color: theme.palette.common.black,
        marginBottom: theme.spacing(1),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            width: 350,
            marginBottom: theme.spacing(0)
        },

        '&:hover:not(.Mui-disabled)::before, &:before': {
            borderColor: theme.palette.common.black
        },

        '&:after': {
            borderBottom: '0'
        }
    },
    filterAcc: {
        marginBottom: theme.spacing(2)
    },
    filterSelect: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    filterSelectInput: {
        width: '100%'
    },
    filterSelectResetButton: {
        marginBottom: theme.spacing(2) * -1
    },
    filterCheckboxInput: {
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(2) * -1
        }
    },
    exportButton: {
        marginRight: theme.spacing(2)
    },
    applicationList: {},
    applicationWrapper: {
        display: 'flex'
    },
    application: {
        padding: theme.spacing(3),
        width: '100%'
    },
    applicationHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    applicationHeaderImage: {
        width: 50,
        height: 50
    },
    applicationData: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3)
    },
    applicationDataRow: {
        paddingBottom: theme.spacing(1)
    },
    applicationProjectsHeader: {
        paddingBottom: theme.spacing(1)
    },
    applicationProjectLink: {
        paddingBottom: theme.spacing(1)
    },
    sla: {
        paddingTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center'
    },
    slaIcon: {
        marginRight: theme.spacing(1)
    }
});

class Applications extends React.Component {
    constructor() {
        super();
        let initialData = mockDataApplicationList;
        let allApplicationTypes = [];
        let allServers = [];

        initialData.forEach(app => {
            if (!(allApplicationTypes.includes(app.applicationType))) {
                allApplicationTypes.push(app.applicationType);
            }
            if (!(allServers.includes(app.server))) {
                allServers.push(app.server);
            }
        });

        allApplicationTypes.sort();
        allServers.sort();

        this.state = {
            filterAccExpanded: false,
            initalApplications: initialData,
            applications: initialData,
            querySet: false,
            zoomIn: true,
            searchValue: '',
            allApplicationTypes: allApplicationTypes,
            allServers: allServers,
            activeApplicationTypeFilter: '',
            activeServerFilter: '',
            activeSLAFilter: false
        };
    }

    handleSearch(value) {
        if (value.length >= 3) {
            let tempState = this.state;
            tempState.searchValue = value;
            tempState.applications = this.filterFunction(tempState, tempState.applications);
            tempState.applications = this.searchFunction(value, tempState.applications);
            this.setState(tempState);
        } else {
            let tempState = this.state;
            let filteredApps = this.filterFunction(tempState, tempState.initalApplications);
            this.setState({searchValue: value, applications: filteredApps});
        }
    }

    handleFilterSelection(value, filter) {
        let tempState = this.state;

        switch (filter) {
            case 'applicationType':
                tempState.activeApplicationTypeFilter = value;
                break
            case 'server':
                tempState.activeServerFilter = value;
                break;
            case 'sla':
                tempState.activeSLAFilter = value;
                break;
            default:
                break;
        }

        tempState.applications = this.filterFunction(tempState, tempState.initalApplications);
        tempState.applications = this.searchFunction(tempState.searchValue, tempState.applications);

        this.setState(tempState);
    }

    searchFunction(value, initalApplications) {
        const applications = initalApplications;
        const searchValue = value.toLowerCase();
        let foundApplications = [];

        applications.forEach(app => {
            for (let key in app) {
                let obj = app[key];

                // Search in Projects
                if (Array.isArray(obj)) {
                    obj.forEach(projectObj => {
                        for (let projectKey in projectObj) {
                            let project = projectObj[projectKey];
                            if (typeof project === 'string') {
                                project.toLowerCase();

                                if (project.includes(searchValue)) {
                                    if (!foundApplications.includes(app)) {
                                        foundApplications.push(app);
                                    }
                                    break;
                                }
                            }
                        }
                    })
                } else {
                    if (typeof obj === 'string') {
                        if (obj.toLowerCase().includes(searchValue)) {
                            if (!foundApplications.includes(app)) {
                                foundApplications.push(app);
                            }
                            break;
                        }
                    }
                }
            }
        });

        return foundApplications;
    }

    filterFunction(state, applicationsToFilter) {
        let initialData = applicationsToFilter
        let alreadyFiltered = false;
        let filtered = false;
        let applications = [];

        function filterAlg(filterValue, findKey) {
            if (filterValue || filterValue.length > 0) {
                if (alreadyFiltered) {
                    let tempRows = [];

                    applications.forEach(app => {
                        if (app[findKey.valueOf()] === filterValue) {
                            tempRows.push(app);
                        }
                    });

                    applications = tempRows;
                } else {
                    initialData.forEach(app => {
                        if (app[findKey.valueOf()] === filterValue) {
                            applications.push(app);
                        }
                    });
                    alreadyFiltered = true;
                }

                filtered = true;
            }
        }

        filterAlg(state.activeApplicationTypeFilter, 'applicationType');
        filterAlg(state.activeServerFilter, 'server');
        filterAlg(state.activeSLAFilter, 'sla');

        if (filtered === false) {
            applications = applicationsToFilter;
        }

        return (applications);
    }

    componentDidMount() {
        this.props.updateSiteTitle(this.props.t('route_applications'));
    }

    render() {
        const {t} = this.props;
        const {classes} = this.props;
        const {location} = this.props;

        const query = new URLSearchParams(location.search);

        if (query.get('searchvalue') && !this.state.querySet) {
            if (this.state.searchValue === '') {
                this.setState({querySet: true});
                this.handleSearch(query.get('searchvalue'));
            }
        }

        return (
            <div className={classes.applications}>
                <div className={classes.filterSearchGroup}>
                    <TextField
                        className={classes.searchField}
                        size="small"
                        value={this.state.searchValue}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        placeholder={t('search')}
                        InputProps={{
                            className: classes.searchField
                        }}
                    />
                    <Button startIcon={<FilterListIcon/>} variant="contained" color="primary"
                            onClick={() => this.setState({filterAccExpanded: !this.state.filterAccExpanded})}>Filter</Button>
                </div>
                <div className={classes.filterAcc}>
                    <Accordion expanded={this.state.filterAccExpanded}
                               onChange={() => this.setState({filterAccExpanded: !this.state.filterAccExpanded})}>
                        <AccordionSummary style={{display: 'none'}}></AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{t('filter_all_entries')}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                    <FormControl className={classes.filterSelectInput}>
                                        <InputLabel
                                            id="applicationType-filter-label">{t('application_type')}</InputLabel>
                                        <Select
                                            labelId="applicationType-filter-label"
                                            id="applicationType-filter"
                                            value={this.state.activeApplicationTypeFilter}
                                            onChange={(e) => this.handleFilterSelection(e.target.value, 'applicationType')}
                                        >
                                            {this.state.allApplicationTypes.map((appType, index) => (
                                                <MenuItem key={index} value={appType}>{t(appType)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton className={classes.filterSelectResetButton}
                                                onClick={() => this.handleFilterSelection('', 'applicationType')}
                                                color="primary" aria-label="Reset Filter"
                                                component="span">
                                        <CloseIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                    <FormControl className={classes.filterSelectInput}>
                                        <InputLabel
                                            id="server-filter-label">{t('server')}</InputLabel>
                                        <Select
                                            labelId="server-filter-label"
                                            id="server-filter"
                                            value={this.state.activeServerFilter}
                                            onChange={(e) => this.handleFilterSelection(e.target.value, 'server')}
                                        >
                                            {this.state.allServers.map((server, index) => (
                                                <MenuItem key={index} value={server}>{server}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <IconButton className={classes.filterSelectResetButton}
                                                onClick={() => this.handleFilterSelection('', 'server')}
                                                color="primary" aria-label="Reset Filter" component="span">
                                        <CloseIcon/>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3} className={classes.filterSelect}>
                                    <FormControl>
                                        <FormControlLabel className={classes.filterCheckboxInput}
                                                          control={<Checkbox
                                                              checked={this.state.activeSLAFilter}
                                                              onChange={(e) => this.handleFilterSelection(!this.state.activeSLAFilter, 'sla')}
                                                              name={t('sla_exists')}/>}
                                                          label={t('sla_exists')}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <Zoom in={this.state.zoomIn}>
                    <Grid container className={classes.applicationList} spacing={2}>
                        {this.state.applications.length > 0 ? this.state.applications.map(app => (
                            <Grid className={classes.applicationWrapper} item xs={12} sm={6} lg={4} xl={3}
                                  key={app.id}>
                                <Paper className={classes.application} square={true}>
                                    <Link component={RouterLink} to={'/applications/' + app.id}
                                          className={classes.applicationLink}>
                                        <div className={classes.applicationHeader}>
                                            <Typography variant="h5">{app.name}</Typography>
                                            <img className={classes.applicationHeaderImage}
                                                 src={process.env.PUBLIC_URL + '/applications-logos/software/' + app.applicationType + '.svg'}
                                                 alt={app.applicationType}/>
                                        </div>
                                    </Link>
                                    <div className={classes.applicationData}>
                                        <Grid className={classes.applicationDataRow} container>
                                            <Grid item xs={6}><Typography
                                                variant="body1"><strong>{t('hosting')}</strong></Typography></Grid>
                                            <Grid item xs={6}><Typography
                                                variant="body1">{app.hosting}</Typography></Grid>
                                        </Grid>
                                        <Grid className={classes.applicationDataRow} container>
                                            <Grid item xs={6}><Typography
                                                variant="body1"><strong>{t('server')}</strong></Typography></Grid>
                                            <Grid item xs={6}><Typography
                                                variant="body1">{app.server}</Typography></Grid>
                                        </Grid>
                                        <Grid className={classes.applicationDataRow} container>
                                            <Grid item xs={6}><Typography
                                                variant="body1"><strong>{t('php_version')}</strong></Typography></Grid>
                                            <Grid item xs={6}><Typography
                                                variant="body1">{app.phpVersion}</Typography></Grid>
                                        </Grid>
                                        <Grid className={classes.applicationDataRow} container>
                                            <Grid item xs={6}><Typography
                                                variant="body1"><strong>{t('app_version')}</strong></Typography></Grid>
                                            <Grid item xs={6}><Typography
                                                variant="body1">{app.appVersion}</Typography></Grid>
                                        </Grid>
                                    </div>
                                    <div>
                                        <Typography variant="body1"
                                                    className={classes.applicationProjectsHeader}><strong>{t('projects')}</strong></Typography>
                                        {app.projects && app.projects.length > 0 ? app.projects.map((project, index) => {
                                            if (index < 3) {
                                                return (
                                                    <Link component={RouterLink} key={index}
                                                          title={project.name}
                                                          to={'/applications/' + app.id + '/' + project.id}>
                                                        <Typography className={classes.applicationProjectLink}
                                                                    variant="body1">{project.name}</Typography>
                                                    </Link>
                                                )
                                            }
                                            return ''
                                        }) : <Typography variant="body1">{t('no_projects')}</Typography>
                                        }
                                        {app.projects.length > 3 ? <Link component={RouterLink}
                                                                         to={'/applications/' + app.id}
                                                                         title={t('more_projects')}
                                                                         className={classes.applicationProjectMoreLink}>
                                            <Typography
                                                variant="body1">... {(app.projects.length - 3) > 1 ? app.projects.length - 3 + ' ' + t('more_projects') : app.projects.length - 3 + ' ' + t('one_more_project')}</Typography>
                                        </Link> : ''}
                                    </div>
                                    {app.sla ?
                                        <div className={classes.sla}>
                                            <StarIcon className={classes.slaIcon}/><Typography variant="body1">{t('sla_exists')}</Typography>
                                        </div> : ''}
                                </Paper>
                            </Grid>
                        )) : <Grid item xs={12}><Typography
                            variant="h5">{t('no_projects_found')}</Typography>
                        </Grid>}
                    </Grid>
                </Zoom>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    siteTitle: state.global.siteTitle,
});

const mapDispatchToProps = {
    updateSiteTitle,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withStyles(useStyles),
    withTranslation()
)(withRouter(Applications));
