import Container from '@material-ui/core/Container';
import {Redirect, Route, Switch} from 'react-router-dom';
import React from 'react';
import {withStyles} from '@material-ui/styles';
import {allRoutes, routeHome} from '../../config/routeItems';
import {compose} from 'redux';

const useStyles = () => ({
    root: {
        flexGrow: 1,
        height: '100%',
    },
});

class AppBody extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Container maxWidth="xl">
                    <Switch>
                        <Redirect exact from="/" to={routeHome.url()}/>
                        {allRoutes.map((item) => (
                            <Route key={item.key} exact={item.exact} path={item.url()}>
                                {item.component}
                            </Route>
                        ))};
                    </Switch>
                </Container>
            </div>
        );
    }
}

export default compose(
    withStyles(useStyles),
)(AppBody);
